// Circular Publication api
export const circularPublicationBaseUrl = '/iabm/circular-publications/'
export const circularPublicationList = circularPublicationBaseUrl + 'listTT'
export const circularPublicationToggleStatus = circularPublicationBaseUrl + 'toggle-status'
export const circularPublicationStore = circularPublicationBaseUrl + 'store'
export const circularPublicationUpdate = circularPublicationBaseUrl + 'update'
export const circularPublicationDestroy = circularPublicationBaseUrl + 'destroy'

// profile store api
export const profileStore = '/personal-infos/store-update'
export const profileShow = '/personal-infos/show'
export const allTrainerData = '/personal-infos/all-trainer-list'
export const profileInfo = '/personal-infos/profile-info'
export const personalDataList = '/personal-infos/personal-info-list'

// training application api
export const trainingAppliedList = '/external-user/training-applications/applied'
export const trainingApplicationStore = '/external-user/training-applications/store'
export const trainingApplicationUpdate = '/external-user/training-applications/update/'
export const bookingApplicationList = '/external-user/training-applications/booking-list'
export const trainingCalendarListApi = '/external-user/training-applications/training-calendar-list'
export const trainingCalenderFiscalYearWise = '/externalUser/training-calendar/training-calender-fiscal-year-wise'
export const certificateIndividualList = '/external-user/training-applications/certificate-list'
export const trainingApplicationIndividualList = '/external-user/training-applications/application-list'

// Release Order
export const certificateReleaseList = '/external-user/release-order/certificate-list'

// Request for document api
export const requestDocumentList = '/external-user/request-for-document/list'
export const requestDocumentStore = '/external-user/request-for-document/store'
export const requestDocumentUpdate = '/external-user/request-for-document/update'
export const requestDocumentToggleStatus = '/external-user/request-for-document/toggle-status'

export const complainList = '/iabm/complain-recommendation/list-tt'
export const complainStore = '/externalUser/complain/store'
export const complainUpdate = '/externalUser/complain/update'
export const complainToggleStatus = '/externalUser/complain/toggle-status'
export const faqSetupList = '/config/faq-setup/traning-list'

export const trainingScheduleBaseUrl = '/externalUser/training-schedule/'
export const trainingScheduleList = trainingScheduleBaseUrl + 'list'
export const externalUserScheduleList = trainingScheduleBaseUrl + 'schedule-list'
export const externalUserScheduleDataList = trainingScheduleBaseUrl + 'external-schedule-list'
export const trainingScheduleCommittee = '/tpm/training-schedule/committee'
export const circularNoList = trainingScheduleBaseUrl + 'circular-no'

// Hand note api
export const handNote = '/externalUser/hand-note/'
export const trainerScheduleList = handNote + 'trainer-schedule-list'
export const trainerCourseList = handNote + 'trainer-course-list'
export const traineeCourseList = handNote + 'trainee-course-list'
export const handNoteStore = handNote + 'store'
export const handNoteShow = handNote + 'show'
export const handNoteCircularList = handNote + 'trainee-circular-list'

// payment api
export const billPaymentBoucher = '/externalUser/bill-payment/boucher'
export const billPaymentReceipt = '/externalUser/bill-payment/receipt'

// invitation api
export const invitation = '/externalUser/invitation/'
export const invitationList = '/externalUser/invitation/list'
export const invitationShow = '/externalUser/invitation/show'
export const invitationPdfDownload = '/iabm/invitation/pdf-download'

// Evaluation api
export const evaluationBaseUrl = '/external-user/evaluations/'
export const evaluationList = evaluationBaseUrl + 'list'
export const evaluationShow = evaluationBaseUrl + 'show'

// Evaluation api
export const courseEvaluationBaseUrl = '/external-user/course-evaluations/'
export const courseEvaluationList = courseEvaluationBaseUrl + 'list'
export const courseEvaluationStore = courseEvaluationBaseUrl + 'store'
export const courseEvaluationShow = courseEvaluationBaseUrl + 'show'

// Trainer Evaluation
export const circularInfo = '/external-user/trainer-evaluation/circular-info'
export const trainerEvaluationInfo = '/external-user/trainer-evaluation/trainer-evaluation-info'
export const trainingModuleWiseTrainer = '/tpm/training-schedule/module-wise-trainer'
export const trainerEvaluationList = '/external-user/trainer-evaluation/list'
export const trainerEvaluationDetailsList = '/external-user/trainer-evaluation/trainer-eval-index'
export const trainerEvaluationStore = '/external-user/trainer-evaluation/store'
export const trainerEvaluationShow = '/external-user/trainer-evaluation/show'
export const trainerEvaluationUpdate = '/external-user/trainer-evaluation/update'
export const trainerEvaluationToggleStatus = '/external-user/trainer-evaluation/toggle-status'

export const traineeIdCard = '/external-user/trainee-id-card'

// Course Evaluation
export const traineeCourseEvaluationList = '/external-user/course-evaluation/schedule-list'
export const circularData = '/external-user/course-evaluation/circular-data'
export const traineeCourseEvaluationStore = '/external-user/course-evaluation/store'
export const traineeCourseEvaluationDetails = '/external-user/course-evaluation/details'

export const certificateBaseUrl = '/tim/certificate/'
export const certificateIndividualShow = certificateBaseUrl + 'certificateShow'
export const certificateList = certificateBaseUrl + 'list'
export const certificateDetails = certificateBaseUrl + 'show'

export const reportHeadingList = '/config/report-head/list'

export const reportHeadingListNew = '/config/report-head/listnew'
export const certificateDetail = ''

// Attendance api
export const attendanceBaseUrl = '/externalUser/attendances/'
export const attendanceList = attendanceBaseUrl + 'list'
export const attendanceStore = attendanceBaseUrl + 'store'
export const attendanceUpdate = attendanceBaseUrl + 'update'
export const attendanceShow = attendanceBaseUrl + 'show'
export const attendanceToggleStatus = attendanceBaseUrl + 'toggle-status'
export const attendancePersonalInfoList = attendanceBaseUrl + 'personal-info-list'
export const circularWiseAttendanceList = attendanceBaseUrl + 'circular-wise_attendance'
export const userCircularList = '/externalUser/circular-publications/' + 'user-circular-publications'
export const userCircularDetails = '/externalUser/circular-publications/' + 'circular-details'
export const circularPublication = '/externalUser/training-calendar/circular-publication'

export const traineeEvalutionBaseUrl = '/external-user/trainee-evaluation/'
export const traineeEvalutionList = traineeEvalutionBaseUrl + 'list'
export const traineeEvalutionEvaList = traineeEvalutionBaseUrl + 'trainee-evalution'

// Bill Payment api
export const billPaymentBaseUrl = '/externalUser/bill-payment/'
export const billPaymentList = billPaymentBaseUrl + 'list'
export const incometaxCertificatePdf = '/tim/bill-payment/incomeTax-certificate-pdf'
export const billPaymentHonorarumIndividualDetails = '/tim/bill-payment/honorarum-individual-details'
export const billPaymentHonorarumDetailsIndividual = '/tim/bill-payment/honorarum-details-individual'
export const honoraruimPdf = '/tim/bill-payment/honorarium-pdf'
export const wordExportHonorarium = '/tim/bill-payment/export-word'
