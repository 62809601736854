<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-table-simple class="tg mt-3" hover small caption-top>
                  <tbody>
                    <tr>
                      <th style="width: 15%"  class="text-right" >{{ $t('elearning_config.fiscal_year') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.organization') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.office_type') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_type_bn : formData.office_type }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.office') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_bn : formData.office }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.name') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.name_bn : formData.name }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.mobile') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(formData.mobile, { useGrouping: false }) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.email') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%">{{ formData.email }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.room_type') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.room_type_bn : formData.room_type }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right" v-if="formData.registration_for !== 1 && formData.registration_for !== 2">{{ $t('elearning_config.room_rent') }}</th>
                      <th class="text-center" style="width: 5%" v-if="formData.registration_for !== 1 && formData.registration_for !== 2">:</th>
                      <td style="width: 30%" v-if="formData.registration_for !== 1 && formData.registration_for !== 2">{{ $n(formData.total_rent) }}</td>
                      <th style="width: 15%"  class="text-right" v-if="formData.registration_for == 2">{{ $t('elearning_accommodation.service_charge') }}</th>
                      <th class="text-center" style="width: 5%" v-if="formData.registration_for == 2">:</th>
                      <td style="width: 30%" v-if="formData.registration_for == 2">{{ $n(formData.service_charge) }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.floor_no') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $n(formData.floor_no) }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('elearning_config.room_no') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%">{{ $n(formData.room_no) }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.start_date') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ formData.start_date | dateFormat }}</td>
                    </tr>
                    <tr>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.end_date') }}</th>
                      <th class="text-center" style="width: 5%">:</th>
                      <td style="width: 30%">{{ formData.end_date | dateFormat }}</td>
                      <th style="width: 15%"  class="text-right">{{ $t('globalTrans.remarks') }}</th>
                      <th style="width: 5%" class="text-center">:</th>
                      <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.remarks_bn : formData.remarks_en }}</td>
                    </tr>
                  </tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      <!-- <pre>{{formData}}</pre> -->
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
    }
  },
  mounted () {
    core.index()
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        id: '',
        name: '',
        email: '',
        mobile: '',
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0,
        org_id: 0,
        room_type_id: 0,
        room_rent_id: 0,
        floor_id: 0,
        room_id: 0
      },
      officeTypeList: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      trainingTitleList: []
    }
  },
  computed: {
    roomTypeList: function () {
      return this.$store.state.ExternalUserTraining.traineeTranerObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    fiscalYearList: function () {
      return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
    },
    trainingTypeList: function () {
      return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
      this.officeTypeList = this.getOfficeTypeList(newValue)
    },
    'formData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    },
    'formData.type_id': function (newValue) {
      this.trainingCategoryList = this.getCategoryList(newValue)
    },
    'formData.category_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'formData.room_type_id': function (newValue) {
      this.roomlist = this.getRoomList(newValue)
      this.floorlist = this.getFloorList(newValue)
      this.roomRentList = this.getRoomRentList(newValue)
    }
  },
  methods: {
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getRoomRentList (typeId) {
      return this.$store.state.TrainingElearning.commonObj.roomRentList.filter(item => item.status === 1 && item.room_type_id === typeId)
    },
    getRoomList (typeId) {
      const roomlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = roomlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.room_no, value: item.room_no }
        } else {
          return { text: item.room_no, value: item.room_no }
        }
      })
      return tempData
    },
    getFloorList (typeId) {
      const floorlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = floorlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.floor_no, value: item.floor_no }
        } else {
          return { text: item.floor_no, value: item.floor_no }
        }
      })
      return tempData
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.ExternalUserIrrigation.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.type_id === typeId)
       }
       return trainingCategoryList
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.category_id === categoryId)
      }
      return trainingTitleList
    },
    onChangeFile (e) {
      this.formData.upload_guidelines = e.target.files[0]
    },
    async bookingApplicationCheck () {
      // if (this.formData.start_date && this.formData.end_date) {
      //   const room = this.roomlist.find(item => item.value === parseInt(this.formData.room_id))
      //   const floor = this.floorlist.find(item => item.value === parseInt(this.formData.floor_id))
      //   const roomRent = this.roomRentList.find(item => item.value === parseInt(this.formData.room_rent_id))
      //   this.formData.room_no = room !== undefined ? room.text : ''
      //   this.formData.floor_no = floor !== undefined ? floor.text : ''
      //   this.formData.room_rent = roomRent !== undefined ? roomRent.text : ''
      //   let result = null
      //   result = await RestApi.postData(trainingElearningServiceBaseUrl, bookingApplicationCheck, this.formData)
      //   this.dateErrorMsg = ''
      //   if (!result.success) {
      //     this.dateErrorMsg = 'Already booked'
      //   }
      // }
    }
  }
}
</script>
